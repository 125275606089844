import React from "react";
import "./App.scss";

import Nav from "./components/nav/Nav";
import Info from "./components/info/Info";
import Hero from "./components/hero/Hero";
import About from "./components/about/About";
import Footer from "./components/footer/Footer";
import Contact from "./components/contact/Contact";
import Service from "./components/service/Service";
//import ContactForm from "./components/form/Form";

import redovisning from "./assets/copy-line.svg";
import deklaration from "./assets/notebook-line.svg";
import likvidation from "./assets/text-documents-line.svg";
import bolagsbildning from "./assets/text-document-check.svg";
import årsredovisningar from "./assets/edit-document.svg";

import email from "./assets/envelope.svg";
import mobile from "./assets/cell-phone.svg";
import address from "./assets/home.svg";

import aboutImg from "./assets/laptop-3196481__340.jpg";
import contactImg from './assets/shaking-hands-3098690__340.jpg';

function App() {
  return (
    <div className="App">
      <Nav />
      <Hero />

      <div id="about">
        <About image={aboutImg} />
      </div>

      <div className="services" id="services">
        <div className="services-header">
          <h1>Våra tjänster</h1>
        </div>
        <div className="services-content">
          <Service
            icon={redovisning}
            title="Löpande redovisning"
            desc="Vi hjälper dig med löpande bokföring och redovisning, Löner och fakturering och kundreskontra. Du får en bra överblick över din ekonomi, enkel rutiner och ett bra arbetsflöde. Vi har ett  starkt personligt kundegengemang och lyssnar på dina önskemål och skräddarsyr en lösning som passar dig.
            "
          />
          <Service
            icon={årsredovisningar}
            title="Årsredovisningar"
            desc="Vi hjälper dig med att upprätta bokslut samt årsredovisningar. Våra revisorpartners säkerställer att allt blir gjort enligt god redovisningssed.
            "
          />
          <Service
            icon={deklaration}
            title="Deklaration"
            desc="Vi hjälper dig med alla typer av deklarationer, moms och periodiska sammanställningar. Vi ser till att denna viktiga del av ditt bolag sköts på ett korrekt sätt."
          />
          <Service
            icon={bolagsbildning}
            title="Bolagsbildning"
            desc="I samband med aktiebolagsbildningar ansvarar vi för all kommunikation med berörda myndigheter samt all dokumentation samt ansökan om F-skattesedel, moms och registrering som arbetsgivare"
          />
          <Service
            icon={likvidation}
            title="Likvidation"
            desc="Vi hjälper till med att avsluta bolag och tar hand om all kommunikation och dokumentation gentemot myndigheter."
          />
        </div>
      </div>

      <div className="info">
        <Info />
      </div>

      <div id="contact">
        <div className="contact-header">
          <h1>Kontakta oss</h1>
        </div>

        <div id="contact-box">
          <img id="contact-image" src={contactImg} alt="" />
          <div id="contact-info">
            <Contact image={mobile} title="Mobil" desc="0707-276302" />

            <Contact
              image={email}
              title="E-post"
              desc="ulfthomas@live.se"
              href="mailto:ulfthomas@live.se"
            />

            <Contact image={address} title="Postadress" desc="Box 33, 438 21 Landvetter" />
            
          </div>
        </div>
      </div>

      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
