import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div>Made by 
      <a href="https://www.ascenda.se/" rel="noopener noreferrer" target="_blank">
         <strong>Ascenda AB</strong>
      </a>
      </div>
    </div>
  );
};

export default Footer;
