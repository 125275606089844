import React from "react";
import "./Info.scss";

import collage from "../../assets/collage.png";

const Info = () => {
  return (
    <div className="info-container">
      <img alt="" src={collage} />
      <h1>Din ekonomi i trygga händer</h1>
      <p>
        Hos oss kan du alltid känna dig trygg, vi har ett starkt kundengagemang
        och erfoderlig kompetens. Vi har en flexibel inställning och sätter oss
        in i din verksamhet för att få förståelse för dina förutsättningar.
        Genom den förståelsen kan vi ge er bästa möjliga tjänster och service.
        <br />
        <br />
        <strong>Affärsidé & Mål</strong>
        <br />
        Vår affärside är att erbjuda ekonomitjänster av hög kvalitet med
        personligt engagemang till företag, föreningar och privatpersoner. Vi
        ska leverera ekonomitjänster med hög kvalitet, ett starkt kundfokus och
        personligt engagemang i varje kund till konkurrenskraftiga priser.{" "}
        <br />
        <br />
        <strong>Vår filosofi</strong>
        <br />• Kundfokus och flexibilitet i hela processen <br />•
        Konkurrenskraftiga priser <br />• Kompetens och god service
      </p>
    </div>
  );
};

export default Info;
