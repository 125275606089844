import React from "react";
import "./Hero.scss";

/* import image from "../../assets/business-3528035_1920.jpg"; */
/* import image from "../../assets/office4-1920.png"; */
import image from '../../assets/office-1920.jpg';

const Hero = () => {
  return (
    <div className="hero" style={{ backgroundImage: `url(${image})` }}>
      <div className="hero-content">
        <h2 className="hero-welcome">Välkommen!</h2>
        <h1 className="hero-header">
          Är du i behov av <br />
          professionell bokföring <br/>och redovisning?
        </h1>
        <a className="hero-cta" href="mailto:ulfthomas@live.se">Boka kostnadsfri konsultation</a>
      </div>
    </div>
  );
};

export default Hero;
