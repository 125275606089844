import React from "react";
import "./Service.scss";

const Service = props => {
  return (
    <div className="service-card">
      <img alt="" src={props.icon} />
      <h2>{props.title}</h2>
      <p>{props.desc}</p>
      <ul>{props.items !== undefined && props.items.map(i => <li>{i}</li>)}</ul>
    </div>
  );
};

export default Service;