import React, { useState } from "react";
import "./Nav.scss";

import logo from "../../assets/icons/logga.jpg";

const Nav = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <nav>
      <ul>
        <img src={logo} alt="" />
        <li style={{ gridArea: "home" }}>
          <a href="/#">Hem</a>
        </li>
        <li style={{ gridArea: "services" }}>
          <a href="#services">Tjänster</a>
        </li>
        <li style={{ gridArea: "contact" }}>
          <a href="#contact">Kontakt</a>
        </li>
      </ul>
      
      <img src={logo} alt="" className="logo-mobile"/>
      <div className={open ? "menu open" : "menu closed"} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div
        className={open ? "menuOpen" : "menuClosed"}
        //eslint-disable-next-line
        onClick={e => (e.stopPropagation(), setOpen(false))}
      >
        <a href="/#">Hem</a>
        <a href="#services">Tjänster</a>
        <a href="#contact">Kontakt</a>
      </div>
    </nav>
  );
};

export default Nav;
