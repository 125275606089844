import React from "react";
import "./Contact.scss";

const Contact = props => {
  return (
    <>
      <img alt="" id="contact-icon" src={props.image} />
      <h1 className="center-vertical">{props.title}</h1>
      <a className="center-vertical" href={props.href}>{props.desc}</a>
    </>
  );
};

export default Contact;
